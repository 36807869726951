
<template>
  <div class="sub-card">
      <div class="section-main">
          <h3 class="heading-number" data-number="◆">{{ title }}</h3>
          <ol class="c-pagelink">
            <li v-for="(info, pageName, i) of linkList" :key="i" :class="{ 'uk-disabled': info.path === $route.path }">
              <router-link :to="{name: pageName}"> {{info.breadcrumb}} </router-link>
            </li>
          </ol>
      </div>
  </div>
  </template>
  
  
  <script>

  export default {
    
    props: {
      title: String,
      linkList:[],
    },
  }
  </script>
  
  
  
  <style scoped lang="scss">
  .c-pagelink {
    padding-left: 0 !important;
    li {
      margin: 30px 10px 20px 20px;
      line-height: 1.8rem;
      
      &:last-of-type { margin-bottom: 0; }
      a { 
        color: #fff;
        text-decoration: underline;
        transition: .3s; 
        &:hover {
          text-decoration: none;
          text-shadow: 0 0 10px #ffc900;
        }
      }
    }
    li a.router-link-exact-active{
        color: rgb(161, 161, 161);
    }
  }
  
  </style>
  