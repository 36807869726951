<template>
    <nav aria-label="Breadcrumb">
        <ul class="uk-breadcrumb">
            <li 
                v-for="(route, i) in breadcrumbs" 
                :key="i"
                :class="{ 'uk-disabled': route.path === $route.path }"
            >
                <router-link :to="route.path">{{ route.meta.breadcrumb }}</router-link>
            </li>
        </ul>
    </nav>
</template>
  
  <script>
  export default {
    computed: {
      breadcrumbs() {
        let pathArray = this.$route.path.split('/')
        pathArray.shift()
        let breadcrumbs = pathArray.reduce((breadcrumbArray, path, i) => {
          breadcrumbArray.push({
            path: (i === 0 ? '' : breadcrumbArray[i-1].path) + '/' + path,
            meta: this.$router.matcher.match((i === 0 ? '' : breadcrumbArray[i-1].path) + '/' + path).meta
          })
          return breadcrumbArray
        }, [])
        return [{ path: '/', meta: { breadcrumb: 'Home' } }].concat(breadcrumbs)
      }
    }
  }
  </script>


<style scoped lang="scss">

.uk-breadcrumb {
    li a {
        color: white;
        font-size: 0.9rem;
        font-weight: 400;
    }
    li a.router-link-exact-active{
        color: rgb(161, 161, 161);
    }
}

</style>